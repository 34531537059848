export const classes = [
    {
      label: "Class 1",
      value: "Class1",
      children: [
        {
          label: "CU (Plain)",
          value: "CU (Plain)",
          area: [
            { label: "0.5", value: 0.5, associatedValue: 36 },
            { label: "0.75", value: 0.75, associatedValue: 24.5 },
            { label: "1.0", value: 1.0, associatedValue: 18.1 },
            { label: "1.5", value: 1.5, associatedValue: 12.1 },
            { label: "2.5", value: 2.5, associatedValue: 7.41 },
            { label: "4", value: 4, associatedValue: 4.61 },
            { label: "6", value: 6, associatedValue: 3.08 },
            { label: "10", value: 10, associatedValue: 1.83 },
            { label: "16", value: 16, associatedValue: 1.15 },
            { label: "25", value: 25, associatedValue: 0.727 },
            { label: "35", value: 35, associatedValue: 0.524 },
            { label: "50", value: 50, associatedValue: 0.387 },
            { label: "70", value: 70, associatedValue: 0.268 },
            { label: "95", value: 95, associatedValue: 0.193 },
            { label: "120", value: 120, associatedValue: 0.153 },
            { label: "150", value: 150, associatedValue: 0.124 },
            { label: "185", value: 185, associatedValue: 0.101 },
            { label: "240", value: 240, associatedValue: 0.0775 },
            { label: "300", value: 300, associatedValue: 0.062 },
            { label: "400", value: 400, associatedValue: 0.0465 },
          ],
        },
        {
          label: "CU (Metal-Coated)",
          value: "CU (Metal-Coated)",
          area: [
            { label: "0.5", value: 0.5, associatedValue: 36.7 },
            { label: "0.75", value: 0.75, associatedValue: 24.8 },
            { label: "1.0", value: 1.0, associatedValue: 18.2 },
            { label: "1.5", value: 1.5, associatedValue: 12.2 },
            { label: "2.5", value: 2.5, associatedValue: 7.56 },
            { label: "4", value: 4, associatedValue: 4.7 },
            { label: "6", value: 6, associatedValue: 3.11 },
            { label: "10", value: 10, associatedValue: 1.84 },
            { label: "16", value: 16, associatedValue: 1.16 },
          ],
        },
        {
          label: "AL",
          value: "AL",
          area: [
            { label: "10", value: 10, associatedValue: 3.08 },
            { label: "16", value: 16, associatedValue: 1.91 },
            { label: "25", value: 25, associatedValue: 1.2 },
            { label: "35", value: 35, associatedValue: 0.868 },
            { label: "50", value: 50, associatedValue: 0.641 },
            { label: "70", value: 70, associatedValue: 0.443 },
            { label: "95", value: 95, associatedValue: 0.32 },
            { label: "120", value: 120, associatedValue: 0.253 },
            { label: "150", value: 150, associatedValue: 0.206 },
            { label: "185", value: 185, associatedValue: 0.164 },
            { label: "240", value: 240, associatedValue: 0.125 },
            { label: "300", value: 300, associatedValue: 0.1 },
            { label: "400", value: 400, associatedValue: 0.0778 },
            { label: "500", value: 500, associatedValue: 0.0605 },
            { label: "630", value: 630, associatedValue: 0.0469 },
            { label: "800", value: 800, associatedValue: 0.0367 },
            { label: "1000", value: 1000, associatedValue: 0.0291 },
            { label: "1200", value: 1200, associatedValue: 0.0247 },
          ],
        },
      ],
    },
    {
      label: "Class 2",
      value: "Class2",
      children: [
        {
          label: "CU (Plain)",
          value: "CU (Plain)",
          area: [
            { label: "0.5", value: 0.5, associatedValue: 36 },
            { label: "0.75", value: 0.75, associatedValue: 24.5 },
            { label: "1.0", value: 1.0, associatedValue: 18.1 },
            { label: "1.5", value: 1.5, associatedValue: 12.1 },
            { label: "2.5", value: 2.5, associatedValue: 7.41 },
            { label: "4", value: 4, associatedValue: 4.61 },
            { label: "6", value: 6, associatedValue: 3.08 },
            { label: "10", value: 10, associatedValue: 1.83 },
            { label: "16", value: 16, associatedValue: 1.15 },
            { label: "25", value: 25, associatedValue: 0.727 },
            { label: "35", value: 35, associatedValue: 0.524 },
            { label: "50", value: 50, associatedValue: 0.387 },
            { label: "70", value: 70, associatedValue: 0.268 },
            { label: "95", value: 95, associatedValue: 0.193 },
            { label: "120", value: 120, associatedValue: 0.153 },
            { label: "150", value: 150, associatedValue: 0.124 },
            { label: "185", value: 185, associatedValue: 0.0991 },
            { label: "240", value: 240, associatedValue: 0.0754 },
            { label: "300", value: 300, associatedValue: 0.0601 },
            { label: "400", value: 400, associatedValue: 0.047 },
            { label: "500", value: 500, associatedValue: 0.0366 },
            { label: "630", value: 630, associatedValue: 0.0283 },
            { label: "800", value: 800, associatedValue: 0.0221 },
            { label: "1000", value: 1000, associatedValue: 0.0176 },
            { label: "1200", value: 1200, associatedValue: 0.0151 },
            { label: "1400", value: 1400, associatedValue: 0.0129 },
            { label: "1600", value: 1600, associatedValue: 0.0113 },
            { label: "1800", value: 1800, associatedValue: 0.0101 },
            { label: "2000", value: 2000, associatedValue: 0.009 },
            { label: "25000", value: 25000, associatedValue: 0.0072 },
          ],
        },
        {
          label: "CU (Metal-Coated)",
          value: "CU (Metal-Coated)",
          area: [
            { label: "0.5", value: 0.5, associatedValue: 36.7 },
            { label: "0.75", value: 0.75, associatedValue: 24.8 },
            { label: "1.0", value: 1.0, associatedValue: 18.2 },
            { label: "1.5", value: 1.5, associatedValue: 12.2 },
            { label: "2.5", value: 2.5, associatedValue: 7.56 },
            { label: "4", value: 4, associatedValue: 4.7 },
            { label: "6", value: 6, associatedValue: 3.11 },
            { label: "10", value: 10, associatedValue: 1.84 },
            { label: "16", value: 16, associatedValue: 1.16 },
            { label: "25", value: 25, associatedValue: 0.734 },
            { label: "35", value: 35, associatedValue: 0.529 },
            { label: "50", value: 50, associatedValue: 0.391 },
            { label: "70", value: 70, associatedValue: 0.27 },
            { label: "95", value: 95, associatedValue: 0.195 },
            { label: "120", value: 120, associatedValue: 0.154 },
            { label: "150", value: 150, associatedValue: 0.126 },
            { label: "185", value: 185, associatedValue: 0.1 },
            { label: "240", value: 240, associatedValue: 0.0762 },
            { label: "300", value: 300, associatedValue: 0.0607 },
            { label: "400", value: 400, associatedValue: 0.0475 },
            { label: "500", value: 500, associatedValue: 0.0369 },
            { label: "630", value: 630, associatedValue: 0.0286 },
            { label: "800", value: 800, associatedValue: 0.0224 },
            { label: "1000", value: 1000, associatedValue: 0.0177 },
            { label: "1200", value: 1200, associatedValue: 0.0151 },
            { label: "1400", value: 1400, associatedValue: 0.0129 },
            { label: "1600", value: 1600, associatedValue: 0.0113 },
            { label: "1800", value: 1800, associatedValue: 0.0101 },
            { label: "2000", value: 2000, associatedValue: 0.009 },
            { label: "25000", value: 25000, associatedValue: 0.0072 },
          ],
        },
        {
          label: "AL",
          value: "AL",
          area: [
            { label: "10", value: 10, associatedValue: 3.08 },
            { label: "16", value: 16, associatedValue: 1.91 },
            { label: "25", value: 25, associatedValue: 1.2 },
            { label: "35", value: 35, associatedValue: 0.868 },
            { label: "50", value: 50, associatedValue: 0.641 },
            { label: "70", value: 70, associatedValue: 0.443 },
            { label: "95", value: 95, associatedValue: 0.32 },
            { label: "120", value: 120, associatedValue: 0.253 },
            { label: "150", value: 150, associatedValue: 0.206 },
            { label: "185", value: 185, associatedValue: 0.164 },
            { label: "240", value: 240, associatedValue: 0.125 },
            { label: "300", value: 300, associatedValue: 0.1 },
            { label: "400", value: 400, associatedValue: 0.0778 },
            { label: "500", value: 500, associatedValue: 0.0605 },
            { label: "630", value: 630, associatedValue: 0.0469 },
            { label: "800", value: 800, associatedValue: 0.0367 },
            { label: "1000", value: 1000, associatedValue: 0.0291 },
            { label: "1200", value: 1200, associatedValue: 0.0247 },
            { label: "1400", value: 1400, associatedValue: 0.0212 },
            { label: "1600", value: 1600, associatedValue: 0.0186 },
            { label: "1800", value: 1800, associatedValue: 0.0165 },
            { label: "2000", value: 2000, associatedValue: 0.0149 },
            { label: "25000", value: 25000, associatedValue: 0.0127 },
          ],
        },
      ],
    },
    {
      label: "Class 5",
      value: "Class5",
      children: [
        {
          label: "CU (Plain)",
          value: "CU (Plain)",
          area: [
            { label: "0.5", value: 0.5, associatedValue: 39 },
            { label: "0.75", value: 0.75, associatedValue: 26 },
            { label: "1.0", value: 1.0, associatedValue: 19.5 },
            { label: "1.5", value: 1.5, associatedValue: 13.3 },
            { label: "2.5", value: 2.5, associatedValue: 7.98 },
            { label: "4", value: 4, associatedValue: 4.95 },
            { label: "6", value: 6, associatedValue: 3.3 },
            { label: "10", value: 10, associatedValue: 1.91 },
            { label: "16", value: 16, associatedValue: 1.21 },
            { label: "25", value: 25, associatedValue: 0.78 },
            { label: "35", value: 35, associatedValue: 0.554 },
            { label: "50", value: 50, associatedValue: 0.386 },
            { label: "70", value: 70, associatedValue: 0.272 },
            { label: "95", value: 95, associatedValue: 0.206 },
            { label: "120", value: 120, associatedValue: 0.161 },
            { label: "150", value: 150, associatedValue: 0.129 },
            { label: "185", value: 185, associatedValue: 0.106 },
            { label: "240", value: 240, associatedValue: 0.0801 },
            { label: "300", value: 300, associatedValue: 0.0641 },
            { label: "400", value: 400, associatedValue: 0.0486 },
            { label: "500", value: 500, associatedValue: 0.0384 },
            { label: "630", value: 630, associatedValue: 0.0287 },
          ],
        },
        {
          label: "CU (Metal-Coated)",
          value: "CU (Metal-Coated)",
          area: [
            { label: "0.5", value: 0.5, associatedValue: 40.1 },
            { label: "0.75", value: 0.75, associatedValue: 26.7 },
            { label: "1.0", value: 1.0, associatedValue: 20 },
            { label: "1.5", value: 1.5, associatedValue: 13.7 },
            { label: "2.5", value: 2.5, associatedValue: 8.21 },
            { label: "4", value: 4, associatedValue: 5.09 },
            { label: "6", value: 6, associatedValue: 3.39 },
            { label: "10", value: 10, associatedValue: 1.95 },
            { label: "16", value: 16, associatedValue: 1.24 },
            { label: "25", value: 25, associatedValue: 0.795 },
            { label: "35", value: 35, associatedValue: 0.565 },
            { label: "50", value: 50, associatedValue: 0.393 },
            { label: "70", value: 70, associatedValue: 0.277 },
            { label: "95", value: 95, associatedValue: 0.21 },
            { label: "120", value: 120, associatedValue: 0.164 },
            { label: "150", value: 150, associatedValue: 0.132 },
            { label: "185", value: 185, associatedValue: 0.108 },
            { label: "240", value: 240, associatedValue: 0.0817 },
            { label: "300", value: 300, associatedValue: 0.0654 },
            { label: "400", value: 400, associatedValue: 0.0495 },
            { label: "500", value: 500, associatedValue: 0.0391 },
            { label: "630", value: 630, associatedValue: 0.0292 },
          ],
        },
      ],
    },
    {
      label: "Class 6",
      value: "Class6",
      children: [
        {
          label: "CU (Plain)",
          value: "CU (Plain)",
          area: [
            { label: "0.5", value: 0.5, associatedValue: 39 },
            { label: "0.75", value: 0.75, associatedValue: 26 },
            { label: "1.0", value: 1.0, associatedValue: 19.5 },
            { label: "1.5", value: 1.5, associatedValue: 13.3 },
            { label: "2.5", value: 2.5, associatedValue: 7.98 },
            { label: "4", value: 4, associatedValue: 4.95 },
            { label: "6", value: 6, associatedValue: 3.3 },
            { label: "10", value: 10, associatedValue: 1.91 },
            { label: "16", value: 16, associatedValue: 1.21 },
            { label: "25", value: 25, associatedValue: 0.78 },
            { label: "35", value: 35, associatedValue: 0.554 },
            { label: "50", value: 50, associatedValue: 0.386 },
            { label: "70", value: 70, associatedValue: 0.272 },
            { label: "95", value: 95, associatedValue: 0.206 },
            { label: "120", value: 120, associatedValue: 0.161 },
            { label: "150", value: 150, associatedValue: 0.129 },
            { label: "185", value: 185, associatedValue: 0.106 },
            { label: "240", value: 240, associatedValue: 0.0801 },
            { label: "300", value: 300, associatedValue: 0.0641 },
          ],
        },
        {
          label: "CU (Metal-Coated)",
          value: "CU (Metal-Coated)",
          area: [
            { label: "0.5", value: 0.5, associatedValue: 40.1 },
            { label: "0.75", value: 0.75, associatedValue: 26.7 },
            { label: "1.0", value: 1.0, associatedValue: 20 },
            { label: "1.5", value: 1.5, associatedValue: 13.7 },
            { label: "2.5", value: 2.5, associatedValue: 8.21 },
            { label: "4", value: 4, associatedValue: 5.09 },
            { label: "6", value: 6, associatedValue: 3.39 },
            { label: "10", value: 10, associatedValue: 1.95 },
            { label: "16", value: 16, associatedValue: 1.24 },
            { label: "25", value: 25, associatedValue: 0.795 },
            { label: "35", value: 35, associatedValue: 0.565 },
            { label: "50", value: 50, associatedValue: 0.393 },
            { label: "70", value: 70, associatedValue: 0.277 },
            { label: "95", value: 95, associatedValue: 0.21 },
            { label: "120", value: 120, associatedValue: 0.164 },
            { label: "150", value: 150, associatedValue: 0.132 },
            { label: "185", value: 185, associatedValue: 0.108 },
            { label: "240", value: 240, associatedValue: 0.0817 },
            { label: "300", value: 300, associatedValue: 0.0654 },
          ],
        },
      ],
    },
  ];
  
  export const NominalCrossSectionalArea = [
    { label: "0.5", value: 0.5 },
    { label: "0.75", value: 0.75 },
    { label: "1.0", value: 1.0 },
    { label: "1.5", value: 1.5 },
    { label: "2.5", value: 2.5 },
    { label: "4", value: 4 },
    { label: "6", value: 6 },
    { label: "10", value: 10 },
    { label: "16", value: 16 },
    { label: "25", value: 25 },
    { label: "35", value: 35 },
    { label: "50", value: 50 },
    { label: "70", value: 70 },
    { label: "95", value: 95 },
    { label: "120", value: 120 },
    { label: "150", value: 150 },
    { label: "185", value: 185 },
    { label: "240", value: 240 },
    { label: "300", value: 300 },
    { label: "400", value: 400 },
    { label: "500", value: 500 },
    { label: "630", value: 630 },
    { label: "800", value: 800 },
    { label: "1000", value: 1000 },
    { label: "1200", value: 1200 },
    { label: "1400", value: 1400 },
    { label: "1600", value: 1600 },
    { label: "1800", value: 1800 },
    { label: "2000", value: 2000 },
    { label: "25000", value: 25000 },
  ];
  